
import './css/main.css';

import barba from '@barba/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';





barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
        {
            name: 'opacity-transition',
            leave(data) {
                return gsap.to(data.current.container, {
                    opacity: 0
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    opacity: 0
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        }
    ],
});


function scrollToTop() {
// window.scrollTo({top: 0, behavior: 'smooth'});
window.scrollTo({top: 0});
};

function gridCheck(data) {
    let grids = document.getElementsByClassName('masGrid');
    if (grids.length > 0) {
        import(/* webpackChunkName: "grid" */ "./js/components/grid.js").then(module => {
            const gridIt = module.default;
            gridIt(data);
        });
    }
}

function projectsCheck(data) {
        import(/* webpackChunkName: "projectsMenu" */ "./js/components/projectsMenu.js").then(module => {
            const projIt = module.default;
            projIt(data);
        });
}


function galleryCheck(data) {
    let gallery = document.getElementsByClassName('gallery');
    if (gallery.length > 0) {
        import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
            const galleryIt = module.default;
            galleryIt(data);
        });
    }
}

barba.hooks.once((data) => {
    projectsCheck(data);
    galleryCheck(data);
});

barba.hooks.afterOnce((data) => {
    gridCheck(data);
    setTimeout(() => {gridCheck(data)}, 500);
});

barba.hooks.before((data) => {
})

barba.hooks.enter((data) => {
    projectsCheck(data);
})

barba.hooks.beforeEnter((data) => {
    scrollToTop();
});


barba.hooks.after((data) => {
    gridCheck(data);
    galleryCheck(data);
});
